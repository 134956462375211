@import 'src/scss/_vars.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500');

.main__aside {
    background-image: none;
}

table {
    border-collapse: collapse;
    border: 1px solid $footer-fill;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-family: "Montserrat";
    font-size: 16px;
    color: $menu-text-color;
}

table, tbody, td {
    border-color: $footer-fill;
}

b, strong {
    color: $footer-fill;
}

p {
    font-family: "Montserrat";
    font-size: 16px;
    color: $menu-text-color;
}
